module.exports = {
    index: {
        grid: [{
                num: '12',
                text: '全球辦事處',
            },
            {
                num: '100+',
                text: '交易產品',
            },
            {
                num: '4',
                text: '權威監管',
            },
            {
                num: '450+',
                text: '專業團隊',
            },
        ],
        product: [{
                title: '投資產品',
                desc: 'CompanyNameTraditional 為投資者提供包括外匯、貴金屬、原油、股票、指數在內的超過一百種差價合約交易產品；所有產品都可在同一個平臺進行交易，帶給投資者面向全球市場最好的投資機會。',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: '資金安全',
                desc: '客戶的資金與公司運營資金隔離存放，資金存在不同的銀行賬戶中,並接受監管機構對投資者的資金保護。',
                imgUrl: require('@/assets/icon_lock.svg'),
            },
            {
                title: '全球服務',
                desc: 'CompanyNameTraditional 在全球擁有450+專業的員工團隊，其員工擁有超過十年的金融行業經驗，為所有客戶提供專業的本地化服務。',
                imgUrl: require('@/assets/icon_community.svg'),
            },
        ],
        experience: {
            title: '最佳交易體驗',
            desc: '我們提供全球知名度最高、最獲廣泛采用的在線交易平臺之一MetaTrader4（MT4）。MT4交易平臺提供強大的圖表分析工具：五十多種技術指標和盤面分析工具。安全可靠、易於使用而又具備高階交易者使用的功能特點，成為在線交易的標準平臺。 ',
            btn: '用MT4進行交易',
        },
        product2: [{
                title: '差價合約',
                desc: '提供超過40種貨幣對，包括主貨幣對、次要貨幣對及其他貨幣對',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: '貴金屬',
                desc: '金銀交易是極具吸引力的替代性投資，市場流動性較大',
                imgUrl: require('@/assets/icon_gold.svg'),
            },
            {
                title: '能源',
                desc: '提供3種廣受歡迎的交易商品：英國布倫特原油、美國WTI輕質原油和美國天然氣',
                imgUrl: require('@/assets/icon_oil.svg'),
            },
            {
                title: '指數',
                desc: '提供十幾種指數，包括多國股市指數及美元指數',
                imgUrl: require('@/assets/icon_indices-1.svg'),
            },
            {
                title: '股票',
                desc: '超過70支在美國及歐洲上市的知名企業股票',
                imgUrl: require('@/assets/icon_shares-1.svg'),
            },
        ],
        area: {
            title: '全球4大洲辦事處',
            desc: '橫跨歐洲、南美洲、亞洲及非洲',
        },
        honor: {
            title: '榮譽獎項',
            list: [{
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳機構業務經紀商',
                    desc: '2020金融科技獎',
                    desc2: '國際財富與金融大獎',
                },
                {
                    imgUrl: require('@/assets/Best-Affiliate-Program.png'),
                    title: '最佳聯盟計劃',
                    desc: '2020差價和約經紀商大獎',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-News-Analysis-Provider.png'),
                    title: '最佳新聞及市場分析提供者',
                    desc: '2020差價和約經紀商大獎',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-Online-Trading-Services.png'),
                    title: '最佳線上交易服務獎',
                    desc: '2020ADVFN國際金融獎',
                    desc2: 'ADVFN',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳差價合約經紀商',
                    desc: 'Rankia.com',
                    desc2: '',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最具交易透明度經紀商',
                    desc: '2019世界差價合約大獎',
                    desc2: '世界差價合約大獎',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳教育科程',
                    desc: '2019世界差價合約大獎',
                    desc2: '世界差價合約大獎',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最值得信賴的交易經紀商',
                    desc: '2019阿聯酋商業獎',
                    desc2: '中東市場雜誌',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳差價合約客戶服務獎',
                    desc: '2019世界金融差價合約獎',
                    desc2: 'World Finance',
                },
            ],
        },
        sysPay: {
            title: '全球知名支付系统',
            desc: '高效率及自動化流程、嚴格的審核以確保客戶的投資安全、多元化的處境渠道',
        },
        transaction: {
            title: '本公司致力於滿足您的差價合約和外匯交易需求',
            desc: ' 所有交易均涉及風險。您的損失可能大於您的入金。',
            desca: '風險政策',
            text: '開始真實的外匯交易',
            btn: '開設真實賬戶',
        },
        brand: {
            title: ' CompanyNameTraditional 是由多家公司共同使用的業務品牌，包括： ',
            text1: ' CompanyNameTraditional 是位於聖文森特和格林納丁斯的有限責任公司，公司編號為 333 LLC 2020。註冊地址是：1st Floor, First St. Vincent Bank Bldg, James Street, Kingstown, St. Vincent and the Grenadines。 ',
            text2: ' CompanyNameTraditional . 獲英國金融行為監管局 (FCA) 授權並受其監管，FCA 許可證號碼為 760555。註冊地址是：1st Floor, 32 Cornhill, London EC3V 3SG, United Kingdom。 ',
            text3: ' CompanyNameTraditional . 獲塞浦路斯證券交易委員會 (CySEC) 授權並受其監管，許可證號碼為 285/15。註冊地址是：159 Leontiou A’ Street, Maryvonne Building Office 204, 3022, Limassol, Cyprus。 ',
            text4: ' CompanyNameTraditional . 獲毛裏求斯共和國的金融服務委員會 (FSC) 授權並受其監管，許可證號碼為 C118023331。註冊地址是：Suite 207, 2nd Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebène, Republic of Mauritius。 ',
            text5: '高風險投資警告： 外匯和差價合約交易具有高度投機性和高風險性，並不適合所有投資者。您可能損失部分或全部投資資金。因此，您的投資金額應該在您可承受的範圍之內。您應當了解保證金交易相關的所有風險。請閱讀完整的',
            risk: '風險披露政策',
            text6: ' 受限製地區：CompanyNameTraditional 的網站信息不針對加拿大的卑詩省、魁北克省和薩斯喀徹溫省地區、日本、臺灣、朝鮮民主主義人民共和國（朝鮮）、伊朗、美國的居民；並且不向身處與發布或使用該信息有違當地法律法規的國家或管轄地之人發送或供其使用。 ',
        },
        bottomSwiper: [{
                text1: 'MetaTrader 4',
                text2: '全球廣泛應用的交易工具、毫秒級高效執行速度、支持EA自動化交易、強大的圖表分析功能、自定義添加多種指標、可設定止盈止損有效管理風險、一站式交易百餘種產品、提供10萬美金模擬賬戶充分練習、支持多種終端設備。',
            },
            {
                text1: 'CompanyNameTraditional ',
                text2: 'CompanyNameTraditional ——為您，有遠見的交易者創建CompanyNameTraditional 是目前為止最專業、最具創新的掌上交易應用程序，專業的操作界面、直觀易用的管理工具、清晰的圖表、細節的縮放，還有許多實用、獨特的功能，將帶給您流暢的交易體驗。',
            },
        ],
        icp: {
            text1: ' © ATFX 版權所有，不得轉載 ',
            text2: '瓊ICP備19002799號',
            text3: '隱私政策',
            text4: 'Cookies政策',
            text5: '條款和條件',
            text6: '網站地圖',
            text7: '友情鏈接',
        },
        topSwiper: [{
                text1: '英超冠軍曼城官方合作夥伴',
                text2: '攜手強者共同成為行業引領',
                text3: '',
            },
            {
                text1: '央行級別頂級監管',
                text2: '七張全球監管牌照',
                text3: '客戶資金隔離存放 、賬戶安全有保障',
            },
            {
                text1: 'WebTrader-在線交易',
                text2: '如此簡單',
                text3: '專為新手交易者而創',
            },
        ],
        headTitle: 'CompanyNameTraditional ',
        btnTran: '進入交易大廳',
        Welcome: '用戶名',
        openAccount: '開立真實賬戶',
        PleaseLogin: '請登錄',
        lang: '語言',
        tableBox: ['商品名稱', '現價', '最低', '最高'],
        introduce1: {
            title: '豐富的交易產品',
            data: [{
                    title: '外匯',
                    imgUrl: require('@/assets/pro_1.png'),
                    text1: '60餘種貨幣對品種',
                    text2: '高達400:1的槓桿',
                    text3: '5X24小時T+0雙向交易',
                },
                {
                    title: '股指/股票',
                    imgUrl: require('@/assets/pro_2.png'),
                    text1: '20餘個全球主要股指',
                    text2: '70餘種全球知名股票',
                    text3: '有利於投資組合多樣化',
                },
                {
                    title: '貴金屬',
                    imgUrl: require('@/assets/pro_3.png'),
                    text1: '最受歡迎的避險品種',
                    text2: '槓桿高達200倍',
                    text3: '具有競爭力的點差',
                },
                {
                    title: 'ETF',
                    imgUrl: require('@/assets/pro_4.png'),
                    text1: '一籃子資產交易形式',
                    text2: '多種資產集合',
                    text3: '靈活槓桿高達20:1',
                },
            ],
        },
        introduce2: {
            title: 'CompanyName Traditional 讓交易更自信',
            content: 'CompanyFullName Traditional （簡稱：CompanyName ，英文名：CompanyNameEnglish ），公司位於北京CBD金融服務區，經中國證券投資基金業協會備案。專注於：新三板掛牌企業投資、上市公司再融資、創投、風投等資本運作領域的服務。服務於高淨值投資者，爲創新型中小企業提供創業投資，助力國內優質中小企業實現產業資本運作的金融機構。',
        },
        introduce3: {
            title: '第三方交易觀點',
            content: 'CompanyName Traditional 精準把握了這個時代給予的巨大投資機會，與券商、銀行、證券機構、私募基金、投行機構、財務公司、律師事務所等建立了長期的戰略合作伙伴關係，以嚴格的風險控制機制，打造多元化的金融產業鏈，持續爲高淨值投資者精選、配置穩健收益的金融產品，爲投資者提供專業、穩健、安全、高效的金融服務。',
        },
        learnMove: '了解更多  ',
    },
    tabBar: ['交易', '收益寶', '客服', '資訊', '我的'],
    product: {
        home: '首頁',
        Balance: '餘額',
        money: '(元)',
        Withdrawal: '取款',
        Inpour: '存款',
        AveragePrice: '均價',
        Time: '時間',
        TimeLimit: '時限',
        Min: '分',
        Rate: '收益',
        Loss: '虧損',
        Increase: '買漲',
        Decrease: '買跌',
        O: '開盤價',
        H: '最高價',
        L: '最低價',
        C: '收盤價',
        smallMin: '分鐘K線',
        hour: '小時k線',
        dayMin: '日k線',
        Trend: '分時圖',
        LatestDeal: '最新成交',
        RecentTransaction: '最近交易',
        User: '用戶',
        Contract: '合約',
        Direction: '方向',
        Wager: '定金',
        Amount: '金額',
        Profit: '輸贏',
        unfinished: '未完成',
        goLogin: '請登錄後查看',
        ConfirmPurchase: '確認購買',
        ConfirmAllin: '全部下單',
        Buy: '購買',
        ExpectedIncome: '預期收入',
        assetType: '資產類型',
        BillingCycle: '結算周期',
        OrderDirection: '訂單方向',
        MinimumBalance: '最低余額',
        MinimumPurchase: '最少購買',
        upToBuy: '最多購買',
        CurrentPrice: '當前價格',
        Confirm: '確認',
        SuccessfulPurchase: '購買成功',
        PleaseWait: '請等待',
        BuyPrice: '買入價格',
        EndPrice: '結束價格',
        settlementCycle: '結算周期',
        profit: '輸贏',
        second: '秒',
        InvestmentAmount: '投資金額',
        unlimited: '不限',
        product: '購買產品',
        InLiquidation: '平倉中',
        profitText: '盈利',
        lossText: '虧損 ',
        success: '恭喜盈利 ',
    },
    profit: {
        title: '收益寶',
        synopsis: '簡介',
        TotalAmount: '總金額',
        YesterdayP: '昨日收益',
        cumulativeIncome: '累計收益',
        DailyInterest: '日利息',
        TransferIn: '轉入',
        TransferOut: '轉出',
        revenueRecord: '收益記錄',
        SingleDay: '單日收益',
        money: '(元)',
        amount: '金額',
        placeholder: '請輸入金額',
        confirm: '確認',
        success: '操作成功',
    },
    news: {
        title: '新聞資訊',
    },
    me: {
        CreditScore: '信用分',
        Inpour: '存款',
        Team: '我的團隊',
        Withdrawal: '取款',
        AccessLog: '存取記錄',
        FundingDetails: '資金明細',
        HistoryOrder: '歷史訂單',
        BankCard: '銀行卡',
        RealName: '實名認證',
        ChangePassword: '修改密碼',
        wlPassword: '修改取款密碼',
        Share: '我要分享',
        Language: '語言切換',
        Logout: '安全退出',
        read: '已認證',
        unread: '未認證',
        close: '關閉',
        codeText: '掃碼創享共贏',
        ShareText: '分享二維碼',
        copyLink: '複製分享鏈接',
        ModifyNickname: '修改昵稱',
        placeholder: '請輸入昵稱',
        nickNakeConfirmBtn: '確認',
        nickNakeCancelBtn: '取消',
        service: '請聯系客服',
        success: '修改成功',
    },
    Team: {
        title: '我的團隊',
        subordinateTitle: '的團隊',
        NickName: '昵稱',
        TeamNum: '下級數量',
        ChildsProfit: '提成',
        ParentProfit: '貢獻提成',
        TotalProfit: '總盈利',
    },
    Inpour: {
        title: '存款',
        Bank: '網銀存款',
        digitalCurrency: '數字貨幣',
        SelectAmount: '選擇金額',
        Amount: '存款金額',
        Submit: '提 交',
    },
    rechargeService: {
        title: '網銀存款',
        OpenBank: '存款銀行',
        CardUserName: '持卡人',
        InpourInformation: '存款信息',
        Amount: '存款金額',
        CardNo: '銀行卡號.',
        placeholder: '請聯繫客服獲取銀行卡賬號',
        Support: '聯繫客服',
        Warm: '温馨提示：',
        line: '* 先查看要入款的銀行賬號信息，然後通過網上銀行或手機銀行進行轉賬，轉賬成功後再如實提交轉賬信息，財務專員查收到信息後會及時添加您的款項。 ',
        line1: '* 請盡可能選擇同行辦理轉賬，可快速到賬。 ',
        line2: '* 存款完成後，保留單據以利核對並確保您的權益。 ',
        line3: '* 如出現存款失敗或存款後未到賬等情況，請聯繫在線客服獲取幫助。 ',
    },
    Withdrawal: {
        title: '取款',
        titleInfo: '取款信息',
        unBind: '請綁定銀行卡',
        unSWIFTCode: '請綁定SWIFT Code',
        AccountBalance: '賬號餘額',
        cashBalance: '可提取金額',
        RrozenAmount: '凍結金額',
        PRNA: '請實名認證',
        RNA: '實名認證',
        WithdrawAmount: '取款金額',
        WithdrawPassword: '取款密碼',
        Submit: '確認提交',
        placeholder: '請輸入取款密碼',
        placeholder1: '取款收取 ',
        placeholder2: '%手續費，本次共計',
        placeholder3: '(元)',
        cardNo: '取款卡號',
        RealName: '真實姓名',
        mobile: '手機號碼',
        IDNumber: '身份證號',
        CertificateAddress: '證件地址',
        CurrentAddress: '現住址',
        tips: '温馨提示',
        tipsMessage: '還未設置提現密碼，馬上前往設置？',
        AmountMessage: '請輸入金額',
        PassMessage: '請輸入正確的取款密碼',
        CommissionText: '實際到賬金額',
    },
    Accesslog: {
        title: '存取記錄',
        Inpour: '存款',
        Withdrawal: '取款',
        Amount: '金額',
        type: '類型',
        Time: '時間',
        Status: '狀態',
    },
    statusLang: {
        Review: '審核中',
        Success: '成功',
        Fail: '未通過',
    },
    FundingDetails: {
        title: '资金明细',
        Upper: '存款',
        Lower: '取款',
        Bet: '投资',
        Win: '平倉',
        BalanceToFinance: '余额转收益宝',
        FinanceeToBalance: '收益宝转余额',
        CashProfit: '下級提現收益',
        money: ' (元)',
    },
    history: {
        title: '歷史訂單',
        Contract: '合約',
        Amount: '金額',
        Time: '時間',
        Details: '詳情',
        Profit: '輸贏',
        money: ' (元)',
        unfinished: '未完成',
    },
    bank: {
        title: '銀行卡',
        editTitle: '綁定銀行卡',
        accountName: '開戶人姓名',
        opening: '開戶銀行',
        point: '開戶網點',
        card: '銀行卡號',
        DigitalCurrency: '數字貨幣',
        usdt: 'USDT地址（非必填）',
        btc: 'BTC地址（非必填）',
        eth: 'ETH地址（非必填）',
        placeholder1: '请输入开户人姓名',
        placeholder2: '请选择开户银行',
        placeholder3: '请输入开户网点名',
        placeholder4: '请输入银行卡号',
        submit: '確認',
        info: '註：如需修改，請聯系客服',
    },
    SWIFTCode: {
        CardUserName: '收款人姓名',
        Area: '收款人所在地',
        DetailArea: '收款人地址',
        OpenBank: '銀行所在地',
        CardNo: '收款賬號',
        usdt: '請輸入SWIFT Code號',
        btc: '請輸入收款賬號',
        eth: '請輸入',
        placeholder1: '請輸入收款人姓名',
        placeholder2: '請輸入收款人所在地',
        placeholder3: '請輸入收款人地址',
        placeholder4: '請輸入銀行所在地',
        submit: '確 定',
        update: '修 改',
        info: '註：如需修改，請聯系客服',
    },
    withPass: {
        title: '取款密碼',
        OldPasswordle: '舊密碼',
        NewPassword: '新密碼',
        ConfirmPassword: '確認密碼',
        placeholder: '請輸入舊密碼',
        placeholder1: '請輸入新密碼',
        placeholder2: '確認新密碼',
        confirm: '確認修改',
    },
    authentication: {
        title: '實名認證',
        RealName: '真實姓名',
        Mobile: '手機號碼',
        IDNumber: '身份證號',
        CertificateAddress: '證件地址',
        CurrentAddress: '現住址',
        CertificateZ: '證件正面照片',
        CertificateF: '證件反面照片',
        warnInfo: '* 實名信息只能填寫一次，請如實填寫',
        Confirm: '確認',
        placeholder: '請輸入真實姓名',
        placeholder1: '請輸入手機號碼',
        placeholder2: '請輸入身份證號',
        placeholder3: '請輸入證件地址',
        placeholder4: '請輸入現住址',
        message: '請填寫完整的表單',
        message1: '請輸入正確的手機號碼',
        success: '實名成功',
    },
    login: {
        title: '登錄',
        lang: '語言',
        Account: '賬號',
        Password: '密碼',
        btn: '登錄',
        ForgotPassword: '忘記密碼',
        NoAccount: '還沒有賬號？',
        Registration: '註冊',
        OtherLogin: '其他登錄',
        fail: '請完善表單',
        success: '登錄成功',
        ageent: '閱讀並同意',
        ageentTitle: '服務協定',
        ageentFail: '請閱讀並勾選服務協定',
        renzheng: '香港網路安全局認證',
        beian: '京公網安備11010202000001號',
    },
    register: {
        title: '註冊',
        lang: '語言',
        Account: '賬號',
        PhoneNumber: '手機號',
        Password: '密碼',
        ConfirmPassword: '確認密碼',
        InvitationCode: '邀請碼',
        btn: '註冊',
        haveAccount: '已有賬號？',
        Login: '登錄',
        placeholder: '請輸入用戶名',
        placeholder1: '請輸入密碼',
        placeholder2: '請再次輸入密碼',
        placeholder3: '請輸入邀請碼',
        placeholder4: '請輸入手機號',
        placeholder5: '请输入賬號',
        placeholder6: '請輸入至少6位的賬號',
        success: '註冊成功',
        message: '請輸入至少6位的密碼',
        message1: '請輸入至少6位的確認密碼',
        message2: '兩次密碼不壹致',
    },
    public: {
        SubmittedSuccessfully: '提交成功',
        select: '請選擇',
        cancel: '取消',
        confirm: '確認',
        RefreshSucceeded: '刷新成功',
        noMoreData: '沒有更多數據了',
        pullingText: '下拉即可刷新 ...',
        ReleaseText: '釋放即可刷新 ...',
        loadingText: '加載中 ...',
    },
    xpgyHome: {
        title: '上海心蘋公益基金會',
        articleTitle: '上海心蘋公益基金會2021年度第一屆理事會第5&6次會議圓滿召開',
        articleContent1: '12月2日下午，上海心蘋公益基金會2021年度第一屆理事會第5&6次會議（以下簡稱「理事會會議」）在上海555大廈12樓會議室圓滿召開。上海心蘋公益基金會理事長賴穎生，副理事長吳偉，理事王國民、何非方出席，監事申屠媛艷、金幸福出席，周曄如、張翊、許新蘋列席會議。',
        articleContent2: ' 2021年，上海心蘋公益基金會在上海民政局的悉心指導及理事會的領導下，共完成4個專項項目，2個設施服務點即將開展服務，並與「上海真愛夢想基金會」達成合作進行聯合勸募行動，全年工作成果得到民政局基金會管理處的高度肯定。同時穩步推進基金會官網的搭建、3A社會組織等級評定以及非營利組織免稅資格認定工作，逐步完善人員配備和團隊架構，並計劃在2022年提升團隊的綜合業務能力和專業技能，積極為符合基金會公益服務宗旨的對象提供支持，進一步做好信息披露工作，做好資金預算工作，確保下一年度管理費合理使用，各項指標力爭達到主管部門對基金會的要求。理事會及監事會在本次會議上聽取了秘書處對2021年度基金會工作情況及2022年工作計劃的匯報，並對基金會的工作成果表示肯定。',
        articleContent3: '理事會會議審議並通過了理事會、監事會的調整事宜，任命周曄如女生擔任上海心蘋公益基金會秘書長，張翊女士擔任副秘書長;審議並通過了基金會章程的修改，討論並審議了基金會各項規章製度。',
        articleContent4: '會議上理事們紛紛獻計獻策，提出心蘋基金會要吸收更多專業人員，做好項目本地化，深耕社區，在上海打好紮實的項目基礎，不僅要發揮線上籌款的長處，同時也要挖掘更多的線下企業進行捐款。',
        articleContent5: '最後，理事長賴穎生表示，在接下來的工作中，自己將與更多的企業資源對接，通過勸募資金增加基金會的項目空間，另一方面加強員工團建，安排好團隊能力培訓和氛圍建設，穩固團隊凝聚力，希望大家擰成一股繩，把基金會工作做好，進一步在上海活躍慈善氛圍，增進行業意識，充分發揮資源與平臺的優勢，共同保障基金會自身的高質量發展。',
        footer1: '上海心蘋公益基金會',
        footer2: '滬ICP備2021034925號',
        footer3: '滬公網安備 31010602006588號',
        footer4: '技術支持: 杭州映派科技有限公司',
    },
}